import { NextSeo as NextSeoBasic } from 'next-seo';
import { OpenGraph } from 'next-seo/lib/types';
import { stringSanitizer } from '~/utils/strings';
interface CaringSeoProps {
  title?: string;
  description?: string;
  keywords?: string;
  openGraph?: string;
  canonical?: string;
  noindex?: boolean;
  nofollow?: boolean;
}

const NextSeo: React.FC<CaringSeoProps> = (props) => {
  const {
    title,
    description,
    keywords,
    openGraph,
    canonical,
    noindex = false,
    nofollow = false,
  } = props;

  let openGraphData = {} as OpenGraph;

  try {
    const dataJsonPrepare = stringSanitizer(openGraph ? openGraph : '');
    openGraphData = !openGraph
      ? {}
      : (JSON.parse(dataJsonPrepare) as OpenGraph);
  } catch {
    console.error(
      'Invalid openGraph: please check your data is a properly formatted JSON Array',
      openGraph
    );
  }

  return (
    <NextSeoBasic
      title={stringSanitizer(title ? title : '')}
      description={stringSanitizer(description ? description : '')}
      noindex={noindex}
      nofollow={nofollow}
      additionalMetaTags={[
        {
          name: 'viewport',
          content: 'width=device-width, initial-scale=1, viewport-fit=cover',
        },
        {
          name: 'keywords',
          content: keywords ? keywords : '',
        },
      ]}
      openGraph={openGraphData}
      twitter={{
        cardType: 'summary_large_image',
      }}
      canonical={noindex ? '' : canonical?.toLowerCase()}
      robotsProps={
        noindex
          ? {}
          : {
              maxImagePreview: 'large',
              maxSnippet: -1,
              maxVideoPreview: -1,
            }
      }
    />
  );
};

export default NextSeo;
